import React, { useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Item } from "./Item";
import { PhotoGallery } from "./PhotoGallery";
import { ItemsStore, ViewStore, ScrollStore, FacetsStore } from "./Store";
import { Viewer } from "../components/Viewer";
import { useEffect } from "react";
import { EditorTable } from "../components/EditorTable";
import { Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import InfiniteScroll from "react-infinite-scroll-component";

export const ItemsCollection = () => {
  const { items } = ItemsStore();
  const { view, setView } = ViewStore();
  const { facets } = FacetsStore();
  const [hasMore, setHasMore] = useState(true);
  const [params, setSearchParams] = useSearchParams();
  const { scrollPosition, setScrollPosition } = ScrollStore();
  const [localScroll, setLocalScroll] = useState(0);

  useEffect(() => {
    if (scrollPosition) {
      window.scrollTo(0, localScroll);
      setScrollPosition(false);
    }
  }, [scrollPosition, localScroll, setScrollPosition]);

  useEffect(() => {
    const onScroll = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setLocalScroll(window.pageYOffset);
      }
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const getCountByType = (type) => {
      if (!type) return facets.total;
      if (facets.counters && facets.counters.dcType) {
        const i = facets.counters.dcType.indexOf(type);
        return i >= 0 ? facets.counters.dcType[i + 1] : 0;
      } else {
        return 0;
      }
    };
    const type = params.get("dcTypeFilter");
    if (getCountByType(type) > 50) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }

    if (items.length > 0 && params.has("id")) {
      const item = items.find((item) => item.id === params.get("id"));
      setView(item);
    }
  }, [items, setView, params, facets]);

  const loadMoreItems = () => {
    if (facets.total - parseInt(params.get("firstRow")) >= 50) {
      params.set("firstRow", parseInt(params.get("firstRow")) + 50);

      setSearchParams(createSearchParams(params));
    } else {
      setHasMore(false);
    }
  };

  return (
    <>
      {params.get("view") === "table" ? (
        <EditorTable />
      ) : params.get("dcTypeFilter") !== "image" ? (
        <Container>
          {view ? (
            <Viewer />
          ) : items !== undefined ? (
            <ItemsWrapper>
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={items.length}
                next={loadMoreItems}
                hasMore={hasMore && facets.total > 50}
                loader={
                  <Stack alignItems="center">
                    <CircularProgress
                      sx={{ color: "black" }}
                      variant="indeterminate"
                      size={50}
                    ></CircularProgress>
                  </Stack>
                }
              >
                <Items>
                  {items.map((item) => {
                    return <Item data={item} key={item.id} />;
                  })}
                </Items>
              </InfiniteScroll>
            </ItemsWrapper>
          ) : null}
        </Container>
      ) : items !== undefined && items.length > 0 ? (
        <PhotoGallery />
      ) : null}
    </>
  );
};

const ItemsWrapper = styled.div`
  width: 100%;
  margin: 16px 24px 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #c6e5f7;
  padding-bottom: 118px;
`;

const Items = styled.div`
  width: 100%;

  display: grid;
  direction: rtl;
  grid-template-columns: 1fr;
  justify-items: center;

  @media (min-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(347px, 1fr));
  }

  grid-gap: 24px;
`;
