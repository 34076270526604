import { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useSearchParams
} from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { TopBar } from "./components/TopBar";
import { SearchBar } from "./components/SearchBar";
import Chips from "./components/Chips";
import { ItemsCollection } from "./components/ItemsCollection";
import { StylesProvider } from "@mui/styles";
import { Sorter } from "./components/Sorter";
import StoreProvider, { FacetsStore, HamburgerStore } from "./components/Store";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  GetSearchResults,
  BuildSolrSearchQuery
} from "./components/SearchEngine";

import { Welcome } from "./components/Welcome";
import { Yomons } from "./components/Yomons";

import { Kedma } from "./components/Kedma";
import { KedmaWelcome } from "./components/KedmaWelcome";

import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
import { NavLinks } from "./components/NavLinks";
import { Authenticator } from "@aws-amplify/ui-react";

import { Protected } from "./components/Protected";
import { RequireAuth } from "./RequireAuth";
import { Login } from "./components/Login";

import archieExports from "./archie-exports";
import { BlockPage } from "./components/BlockPage";
import SolrParams from './SolrParams';

function Main() {
  const { setCreators, setCollections } = FacetsStore();

  const [params] = useSearchParams();
  const [runSearch, setRunSearch] = useState(false);
  const [showScroll, setShowScroll] = useState(false);

  const { hamburger } = HamburgerStore();


  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setShowScroll(false);
  };

  if (runSearch) {
    let searchQuery = "";
    searchQuery = BuildSolrSearchQuery(params);
    GetSearchResults(searchQuery, params, false);
    setRunSearch(false);
  }

  useEffect(() => {
    const onScroll = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if ([...params].length > 0) {
      setRunSearch(true);
    }
  }, [params]);

  useEffect(() => {
    const getAllCollections = () => {
      let solr_params = new SolrParams();
      solr_params.q = "dcType:collection";
      solr_params.sort = "dcTitleString asc";
      solr_params.rows = 1000;
      let url = archieExports["docs_endpoint"] + "?" + solr_params.toQuerySring();
      return axios.get(url).then(res => {
        const result = res.data;
        const collectionsLen = result.response.docs.length;
        const collections = ["כולם"];
        for (let i = 0; i < collectionsLen; i++) {
          collections.push(result.response.docs[i].dcTitleString);
        }
        setCollections(collections);
      });
    };

    const getDcCreators = () => {
      let solr_params = new SolrParams();
      solr_params.q = "dcCreator:*";
      solr_params.facet = "on";
      solr_params.facet_field = "dcCreator";
      solr_params.facet_sort = "index";
      solr_params.facet_limit = 1000000;
      let url = archieExports["docs_endpoint"] + "?" + solr_params.toQuerySring();
      return axios.get(url).then(res => {
        const result = res.data;
        const creatorsLen = result.facet_counts.facet_fields.dcCreator.length;
        const creatorArr = ["כולם"];
        for (let i = 2; i < creatorsLen; i += 2) {
          creatorArr.push(result.facet_counts.facet_fields.dcCreator[i]);
        }
        setCreators(creatorArr);
      });
    };
    getDcCreators();
    getAllCollections();
  }, [setCollections, setCreators]);
  return (
    <>
      {hamburger ? (
        <NavLinks topBarLinks={topBarLinks} />
      ) : (
        <MainContainer>
          <TopBar topBarLinks={topBarLinks} />

          <Container>
            <SearchBar />
            <Chips />
          </Container>
          <OptContainer>
            <Sorter />
          </OptContainer>
          <ItemsCollection />
          {showScroll ? (
            <ScrollUpButton onClick={scrollToTop}>
              <ScrollIcon></ScrollIcon>
              <ScrollLabel>למעלה</ScrollLabel>
            </ScrollUpButton>
          ) : null}
        </MainContainer>
      )}
    </>
  );
}

export const AddDateTitle = ({ dateTime }) => {
  if (dateTime) {
    const date = dateTime.split("T");

    const event = new Date(date[0]);

    const monthOpt = { month: "long" };
    const dayOpt = { day: "numeric" };
    const yearOpt = { year: "numeric" };

    return (
      <SubHeader>
        תאריך: {event.toLocaleDateString("he-IL", dayOpt)}{" "}
        {event.toLocaleDateString("he-IL", monthOpt)}{" "}
        {event.toLocaleDateString("he-IL", yearOpt)}
      </SubHeader>
    );
  } else {
    return <SubHeader />;
  }
};

function App() {
  return (
    <StoreProvider>
      <StylesProvider injectFirst></StylesProvider>
      <Authenticator.Provider>
        <Routes>
          <Route path="/" element={<Navigate to="/welcome" />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/yomons" element={<Yomons />} />
          <Route path="/kedma" element={<Kedma />} />
          <Route path="/kedma-welcome" element={<KedmaWelcome />} />
          <Route path="/search" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/block" element={<BlockPage />} />
          <Route
            path="/protected"
            element={
              <RequireAuth>
                <Protected />
              </RequireAuth>
            }
          />
        </Routes>
      </Authenticator.Provider>
    </StoreProvider>
  );
}
const SubHeader = styled.div`
  height: 20px;
  margin: 4px 16px 4px 18px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: right;
  color: rgba(0, 0, 0, 0.6);
`;
const MainContainer = styled.div``;
const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  background-color: #e7f6ff;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

const OptContainer = styled.section`
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #e7f6ff;
  display: none;
  justify-content: space-between;
  @media (min-width: 800px) {
    display: block;
    flex-direction: row-reverse;
  }
`;

const StyledFacebookIcon = styled(FacebookTwoToneIcon)`
  width: 16px;
  height: 16px;
  margin: 0px 0px 0px 8px;
`;

const ScrollUpButton = styled.button`
  width: 104px;
  height: 36px;
  border-radius: 4px;
  z-index: 100;
  position: fixed;
  background-color: #0c2b49;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 90%;
  margin-right: 22px;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const ScrollLabel = styled.div`
  width: 40px;
  height: 16px;
  margin: 4px 5.9px 4px 0;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
`;

const ScrollIcon = styled(ArrowUpwardIcon)`
  width: 16px;
  height: 16px;
  color: white;
`;

export const topBarLinks = [
  { title: "ארכיון קדמה", href: "/kedma-welcome" },
  { title: "ויקיבוץ בארי", href: "https://wikibbutz.beeri.org.il/wiki/" },
  {
    title: "מחזורים",
    href:
      "https://wikibbutz.beeri.org.il/wiki/index.php/%D7%A7%D7%98%D7%92%D7%95%D7%A8%D7%99%D7%94:%D7%9E%D7%97%D7%96%D7%95%D7%A8%D7%99%D7%9D"
  },
  {
    title: "ציר הזמן",
    href:
      "https://wikibbutz.beeri.org.il/wiki/index.php/%D7%AA%D7%90%D7%A8%D7%99%D7%9B%D7%95%D7%9F_%D7%A7%D7%99%D7%91%D7%95%D7%A5_%D7%91%D7%90%D7%A8%D7%99"
  },
  {
    title: "ארכיון בארי",
    icon: <StyledFacebookIcon />,
    href: "https://www.facebook.com/ארכיון-בארי-1498112090513864"
  },
  {
    title: "תמונות בארי",
    icon: <StyledFacebookIcon />,
    href: "https://www.facebook.com/תמונות-בארי-1044782262256701"
  }
];

export default App;
