import styled from "styled-components";

import React, { useEffect, useState } from "react";

import { TopBar } from "./TopBar";
import { SearchBar } from "./SearchBar";
import { Logo } from "../svgs/Logo";
import { HamburgerStore, FacetsStore } from "./Store";
import { NavLinks } from "./NavLinks";
import { Footer } from "./Footer";
import { Item } from "./Item";
import axios from "axios";
import Chips from "./Chips";
import { topBarLinks } from "../App";
import archieExports from "../archie-exports";

import SolrParams from "../SolrParams";

export const Welcome = () => {
  const { hamburger } = HamburgerStore();

  const { setCreators, setCollections, setFacets } = FacetsStore();
  const [recentItems, setRecentItems] = useState([]);
  const [access, setAccess] = useState("public");

  useEffect(() => {
    const checkAccess = async () => {
      const res = await axios.get("https://api.ipify.org?format=json");
      if (archieExports["beeri_ip"].includes(res.data.ip)) {
        setAccess("");
      }
    };

    checkAccess();
  }, []);

  useEffect(() => {
    let solr_params = new SolrParams();
    solr_params.fq = [];
    solr_params.sort = "importTime%20desc,id%20asc";
    solr_params.start = 0;
    solr_params.rows = 3;
    solr_params.hl = "on";
    solr_params.hl_fl = "dcTitle,dcDescription,content";
    solr_params.facet = "on";
    solr_params.facet_field = "dcType";
    if (access) solr_params.fq.push('dcAccessRights:"' + access + '"');

    const getRecentItems = () => {
      let url =
        archieExports["docs_endpoint"] + "?" + solr_params.toQuerySring();
      return axios.get(url).then((res) => {
        const result = res.data.response.docs;
        setFacets({
          total: res.data.response.numFound,
          counters: res.data.facet_counts.facet_fields,
          highlights: res.data.highlighting,
        });
        setRecentItems(result);
      });
    };

    const getAllCollections = () => {
      let solr_params = new SolrParams();
      solr_params.q = "dcType:collection";
      solr_params.sort = "dcTitleString asc";
      solr_params.rows = 1000;
      let url =
        archieExports["docs_endpoint"] + "?" + solr_params.toQuerySring();
      return axios.get(url).then((res) => {
        const result = res.data;
        const collectionsLen = result.response.docs.length;
        const collections = ["כולם"];
        for (let i = 0; i < collectionsLen; i++) {
          collections.push(result.response.docs[i].dcTitleString);
        }
        setCollections(collections);
      });
    };

    const getDcCreators = () => {
      let solr_params = new SolrParams();
      solr_params.q = "dcCreator:*";
      solr_params.facet = "on";
      solr_params.facet_field = "dcCreator";
      solr_params.facet_sort = "index";
      solr_params.facet_limit = 1000000;
      let url =
        archieExports["docs_endpoint"] + "?" + solr_params.toQuerySring();
      console.info(url);
      return axios.get(url).then((res) => {
        const result = res.data;
        const creatorsLen = result.facet_counts.facet_fields.dcCreator.length;
        const creatorArr = ["כולם"];
        for (let i = 2; i < creatorsLen; i += 2) {
          creatorArr.push(result.facet_counts.facet_fields.dcCreator[i]);
        }
        setCreators(creatorArr);
      });
    };

    getDcCreators();
    getAllCollections();
    getRecentItems();
  }, [setCollections, setCreators, setFacets, access]);

  return (
    <Container>
      {hamburger ? (
        <NavLinks topBarLinks={topBarLinks} />
      ) : (
        <>
          <Header />
          <Center />
          <Recent recentItems={recentItems} />
          <Footer />
        </>
      )}
    </Container>
  );
};

export const Header = () => {
  return (
    <HeaderContainer>
      <TopBar topBarLinks={topBarLinks} />
      <LogoAndSearch />
    </HeaderContainer>
  );
};

export const LogoAndSearch = () => {
  return (
    <LogoAndSearchContainer>
      <LogoContainer>
        <Logo width={120} height={139} />
      </LogoContainer>
      <Title>ארכיון בארי</Title>
      <SearchBar />
      <Chips />
    </LogoAndSearchContainer>
  );
};

export const Center = () => {
  return (
    <CenterContainer>
      <CenterImage src="./assets/beeri-panorama.jpeg" />
      <ArchieWelcome>
        <StyledH1>
          ברוכים הבאים לארכיון הדיגיטלי של קיבוץ בארי (בהרצה){" "}
        </StyledH1>
        <StyledH2>
          באתר ניתן למצוא את הגרסה הדיגיטלית של פריטים מתוך הארכיון: מסמכים,
          תמונות, סרטי וידאו, הקלטות, גליונות עיתונות מקומית וקטעי עיתונות
          ארצית, וכמובן - 70 שנות "יומון בארי".{" "}
        </StyledH2>
      </ArchieWelcome>
    </CenterContainer>
  );
};

export const Recent = ({ recentItems }) => {
  return (
    <RecentContainer>
      <StyledH1>הועלו לאחרונה</StyledH1>
      <Items>
        {recentItems !== undefined
          ? recentItems.map((item) => {
              return <Item data={item} key={item.id} />;
            })
          : null}
      </Items>
    </RecentContainer>
  );
};

const LogoContainer = styled.div`
  margin-top: 32px;
`;
const ArchieWelcome = styled.section`
  margin-left: 16px;
  margin-right: 16px;
  max-width: 467px;
`;

const Title = styled.h1`
  font-family: Rubik;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin-top: 10px;
  margin-bottom: 8px;
  @media (min-width: 500px) {
    margin-bottom: 24px;
  }
`;

const StyledH1 = styled.h2`
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #353535;
  margin-top: 34px;
  width: 100%;
`;
const StyledH2 = styled.h3`
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #353535;
  margin-top: 16px;
  margin-bottom: 32px;
`;
const LogoAndSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  width: 100%;
  background-color: #e7f6ff;
`;

const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenterImage = styled.img`
  object-fit: cover;
  height: 345px;
  width: 100%;
`;

const RecentContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: #e7f3cf;
`;

const Items = styled.div`
  display: grid;
  direction: rtl;
  margin: 0px 34px 50px 18px;
  grid-template-columns: 1fr;
  justify-items: center;
  @media (min-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(347px, 1fr));
  }

  grid-gap: 24px;
`;
