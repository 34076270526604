import styled from "styled-components";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { AddDateTitle } from "../App";
import { FacetsStore, ViewStore, ItemsStore } from "./Store";
import { useSearchParams } from "react-router-dom";

import { EditForm } from "./EditForm";
export function ViewerInfo({
  data,
  setShowInfoView,
  showInfoView,
  editInfo,
  setEditInfo,
  setData,
  handleCloseClick,
}) {
  const [params, setSearchParams] = useSearchParams();
  const { facets } = FacetsStore();
  const { setView } = ViewStore();
  const { setItems } = ItemsStore();

  const detailTitles = [
    { title: "יוצרי המסמך", dc: "dcCreator" },
    { title: "סוג", dc: "dcType" },
    { title: "פורמט", dc: "dcFormat" },
    { title: "סיווג", dc: "dcAccessRights" },
    { title: "אוסף", dc: "dcIsPartOf" },
    { title: "נושאים", dc: "dcSubject" },
    { title: "מיקום", dc: "storageLocation" },
  ];

  const hightlights = (type) => {
    let retVal = [];
    if (!type) {
      return retVal;
    }
    if (type.content) {
      retVal.push(
        <SearchWordsBox>
          <Detail searchBox={true}>
            <DetailHeader searchBox={true}>
              מילות החיפוש בתוכן&nbsp;
            </DetailHeader>
            <DetailData
              searchBox={true}
              dangerouslySetInnerHTML={{
                __html: data.id ? facets.highlights[data.id].content[0] : "",
              }}
            ></DetailData>
          </Detail>
          <Break />
        </SearchWordsBox>
      );
    }
    if (type.dcDescription) {
      retVal.push(
        <SearchWordsBox>
          <Detail searchBox={true}>
            <DetailHeader searchBox={true}>
              מילות החיפוש בתיאור&nbsp;
            </DetailHeader>
            <DetailData
              searchBox={true}
              dangerouslySetInnerHTML={{
                __html: data.id
                  ? facets.highlights[data.id].dcDescription[0]
                  : "",
              }}
            ></DetailData>
          </Detail>
        </SearchWordsBox>
      );
    }
    if (type.storageLocation) {
      retVal.push(
        <SearchWordsBox>
          <Detail searchBox={true}>
            <DetailHeader searchBox={true}>
              מילות החיפוש במיקום&nbsp;
            </DetailHeader>
            <DetailData
              searchBox={true}
              dangerouslySetInnerHTML={{
                __html: data.id
                  ? facets.highlights[data.id].storageLocation[0]
                  : "",
              }}
            ></DetailData>
          </Detail>
        </SearchWordsBox>
      );
    }

    if (type.dcTitle) {
      retVal.push(
        <SearchWordsBox>
          <Detail searchBox={true}>
            <DetailHeader searchBox={true}>
              מילות החיפוש בכותרת&nbsp;
            </DetailHeader>
            <DetailData
              searchBox={true}
              dangerouslySetInnerHTML={{
                __html: data.id ? facets.highlights[data.id].dcTitle[0] : "",
              }}
            ></DetailData>
          </Detail>
        </SearchWordsBox>
      );
    }
    return retVal;
  };

  const tagHandler = (e, tagType) => {
    if (e.target.text) {
      setItems([]);
      if (tagType === "dcSubject") {
        params.set(tagType, e.target.text);
      } else if (tagType === "dcCreator") {
        params.set("dcCreatorFilter", e.target.text);
      }
      params.set("searchTerm", "*");

      params.set("searchTermModifier", "any");

      params.set("firstRow", 1);

      params.set("numberOfRows", 50);

      params.set("sortField", "dcDate");

      params.set("sortOrder", "desc");

      setSearchParams(params);
      setView(null);
    }
  };
  const tag = (item, arrayLen, tagType) => {
    return (
      <>
        <TagLink onClick={(e) => tagHandler(e, tagType)}>{item}</TagLink>
        {arrayLen > 1 && item.length > 0 ? <span> | </span> : null}
      </>
    );
  };

  const detailsGroup = () => {
    return detailTitles.map((item, index) => {
      if (data[item.dc]) {
        return (
          <Detail key={index} searchBox={false}>
            <DetailHeader searchBox={false}>{item.title}:&nbsp;</DetailHeader>
            <DetailData searchBox={false}>
              {Array.isArray(data[item.dc])
                ? data[item.dc].map((i) =>
                    tag(i, data[item.dc].length, item.dc)
                  )
                : data[item.dc]}
            </DetailData>
          </Detail>
        );
      } else return null;
    });
  };
  return editInfo ? (
    <Container showInfoView={showInfoView}>
      <StyledCloseIcon onClick={() => setShowInfoView(false)} />

      <EditForm
        data={data}
        setEditInfo={setEditInfo}
        setData={setData}
        handleCloseClick={handleCloseClick}
      />
    </Container>
  ) : (
    <Container showInfoView={showInfoView}>
      <StyledCloseIcon onClick={() => setShowInfoView(false)} />
      <Header>
        <Title>{data.dcTitle}</Title>
        <AddDateTitle dateTime={data.dcDate} />
        <Description>
          {data.dcDescription} <Break />
        </Description>
        {facets.length > 0
          ? [facets.highlights[data.id]].map((item) => hightlights(item))
          : null}
      </Header>
      {detailsGroup()}
    </Container>
  );
}

const TagLink = styled.a`
  color: blue;
  cursor: pointer;
`;

const Container = styled.div`
  direction: ltr;
  width: 100%;
  background-color: white;
  z-index: 202;
  height: 100vh;
  overflow-y: overlay;
  margin-bottom: 0;
  overflow-x: hidden;
  visibility: ${(props) => (props.showInfoView ? "visible" : "hidden")};
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;

  margin: 48px 24px 8px 22px;
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
  direction: rtl;
`;

const Description = styled.div`
        display: flex;
        margin: 8px 24px 0px 24px;
        flex-direction: column;
        font-family: Rubik;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: right;
        color: rgba(0, 0, 0, 0.8);
        word-wrap: break-word;
        white-space: pre-wrap;
        direction: rtl;
      
      }`;

const Detail = styled.div`
        display: flex;
  margin: ${(props) =>
    props.searchBox ? "17px 0px 0px 0px" : "8px 24px 0px 24px"};
       direction: rtl;
    
flex-direction: ${(props) => (props.searchBox ? "column" : "row")};
        
        }`;

const DetailHeader = styled.div`
        
        font-family: Rubik;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: right;
color: ${(props) => (props.searchBox ? "rgba(0, 0, 0, 0.8)" : "#000")};
        
        }`;

const DetailData = styled.div`
        font-family: Rubik;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: right;
color: ${(props) => (props.searchBox ? "rgba(0, 0, 0, 0.8)" : "#000")};
        
          word-wrap: break-word;
        
        }`;

const SearchWordsBox = styled.div`
        width: calc(100% - 48px);
        
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 13px;
        
        }`;

const StyledCloseIcon = styled(CloseIcon)`
        cursor: pointer;
        width: 24px;
          height: 24px;
          margin: 14px 14px;
          position: fixed;
        }`;

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin-top: 8px;
`;
