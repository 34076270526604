import React, { useState, useContext } from "react";

const DocItemsContext = React.createContext();
const ViewContext = React.createContext();
const FacetsContext = React.createContext();
const HamburgerContext = React.createContext();
const ScrollContext = React.createContext();
export function ItemsStore() {
  return useContext(DocItemsContext);
}

export function ViewStore() {
  return useContext(ViewContext);
}

export function FacetsStore() {
  return useContext(FacetsContext);
}

export function HamburgerStore() {
  return useContext(HamburgerContext);
}

export function ScrollStore() {
  return useContext(ScrollContext);
}

function StoreProvider({ children }) {
  const [items, setItems] = useState([]);
  const [facets, setFacets] = useState([]);
  const [view, setView] = useState(null);

  const [creators, setCreators] = useState([]);
  const [collections, setCollections] = useState([]);

  const [hamburger, setHamburger] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(false);

  return (
    <DocItemsContext.Provider value={{ items, setItems }}>
      <ViewContext.Provider value={{ view, setView }}>
        <FacetsContext.Provider
          value={{
            facets,
            setFacets,
            creators,
            setCreators,
            collections,
            setCollections,
          }}
        >
          <HamburgerContext.Provider value={{ hamburger, setHamburger }}>
            <ScrollContext.Provider
              value={{ scrollPosition, setScrollPosition }}
            >
              {children}
            </ScrollContext.Provider>
          </HamburgerContext.Provider>
        </FacetsContext.Provider>
      </ViewContext.Provider>
    </DocItemsContext.Provider>
  );
}

export default StoreProvider;
