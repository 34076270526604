import styled from "styled-components";
import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ViewStore } from "./Store";
import { AddDateTitle } from "../App";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import archieExports from "../archie-exports";

function ItemTitle({ title, dateTime, desc }) {
  let trimmed = "";
  if (desc) {
    desc += " ";
    var maxLength = 120; // maximum number of characters to extract
    //trim the string to the maximum length
    trimmed = desc.substr(0, maxLength);

    //re-trim if we are in the middle of a word
    trimmed = trimmed.substr(
      0,
      Math.min(trimmed.length, trimmed.lastIndexOf(" "))
    );
  }

  return (
    <Title>
      <Header>{title}</Header>
      <AddDateTitle dateTime={dateTime} />
      <Description>
        {desc ? (desc.length > 120 ? trimmed + "..." : trimmed) : ""}
      </Description>
    </Title>
  );
}

function ItemContent({ itemData }) {
  function getUrl(itemData) {
    const { dcAccessRights, id, dcFormat } = itemData;
    const type = getPreviewTag(dcFormat);
    let S3url = archieExports["private_assets_bucket"] + "/thumbnails/";
    let S3OriginalsUrl = archieExports["private_assets_bucket"] + "/originals/";
    if (dcAccessRights === "public") {
      S3url = archieExports["public_assets_bucket"] + "/thumbnails/";
      S3OriginalsUrl = archieExports["public_assets_bucket"] + "/originals/";
    }
    if (type === "audio") {
      return S3OriginalsUrl + id + "." + dcFormat;
    }

    return S3url + id + ".webp";
  }

  function onImgError(e) {
    e.target.style.display = "none";
  }

  const getPreviewTag = (dcFormat) => {
    switch (dcFormat) {
      case "pdf":
      case "jpg":
      case "jpeg":
      case "tif":
      case "tiff":
      case "png":
      case "gif":
      case "webp":
        return "image";
      case "ogg":
      case "wav":
      case "mp3":
        return "audio";
      case "ogv":
      case "mov":
      case "avi":
      case "mp4":
      case "m4v":
        return "video";
      default:
        return "unsupported";
    }
  };

  const renderSwitch = () => {
    if (itemData.dcAccessRights === "secret") {
      return (
        <Lock>
          <LockShakle />
          <LockBody />
        </Lock>
      );
    }
    switch (getPreviewTag(itemData.dcFormat)) {
      case "image":
        return (
          <Img
            alt="תמונה ממוזערת למוצג מסוג תמונה"
            src={getUrl(itemData)}
            onError={onImgError}
          />
        );

      case "audio":
        return (
          <AudioWrapper
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Audio
              progressJumpSteps={{ backward: 30000, forward: 30000 }}
              showJumpControls={true}
              src={getUrl(itemData)}
            ></Audio>
          </AudioWrapper>
        );
      case "video":
        return (
          <ImgContainer>
            <Img
              alt="תמונה ממוזערת למוצג מסוג וידאו"
              src={getUrl(itemData)}
              onError={onImgError}
            />
            <VideoIcon />
          </ImgContainer>
        );

      default:
        return;
    }
  };
  return <Content>{renderSwitch()}</Content>;
}

export const Item = ({ data }) => {
  const [params, setSearchParams] = useSearchParams();
  let location = useLocation();
  let navigate = useNavigate();
  const { setView } = ViewStore();

  const handleClick = () => {
    if (location.pathname === "/welcome") {
      params.set("searchTermModifier", "any");
      params.set("firstRow", 1);
      params.set("numberOfRows", 50);
      params.set("sortField", "importTime");
      params.set("sortOrder", "desc");
      params.set("dcTypeFilter", "");
      params.set("searchTerm", "*");
      setSearchParams(params);

      navigate("/search?" + params.toString(), { replace: false });
    } else if (location.pathname === "/kedma-welcome") {
      params.set("searchTermModifier", "any");
      params.set("firstRow", 1);
      params.set("numberOfRows", 50);
      params.set("sortField", "importTime");
      params.set("sortOrder", "desc");
      params.set("dcTypeFilter", "");
      params.set("searchTerm", "*");
      setSearchParams(params);

      navigate("/kedma?" + params.toString(), { replace: false });
    } else {
      setSearchParams(params);
    }
    setView(data);
  };

  return (
    <Container onClick={handleClick}>
      <ItemTitle
        title={data.dcTitle}
        dateTime={data.dcDate}
        desc={data.dcDescription}
      ></ItemTitle>
      <ItemContent itemData={data}></ItemContent>
    </Container>
  );
};

const Container = styled.div`
            width: 100%;
            display: flex;
            flex-direction: column;
            max-height: 382px;
            cursor: pointer;

            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
            border-radius: 4px 4px 0 0;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            @media (min-width: 395px) {
              margin - left: 20px;
            height: 382px;
            max-width: 514px;
  }
            `;

const Title = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  border-bottom: solid 1px #d8d8d8;
`;

const Header = styled.div`
  padding: 16px 16px 0 16px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
`;

const Description = styled.div`
  margin: 4px 16px 16px 18px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: right;
  color: #000;
`;

const Content = styled.div`
  overflow: hidden;
  max-height: 310px;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 0 0 4px 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 395px) {
    height: 310px;
  }
`;

const Img = styled.img`
  max-width: 100%;

  width: auto;
  max-height: 310px;
  object-fit: contain;

  @media (min-width: 395px) {
    height: 310px;
    max-height: 395px;
  }
`;

const ImgContainer = styled.div`
            position: relative;
            display: inline-block;
            max-width: 100%;
            max-height: 310px;

            @media (min-width: 395px) {
              max - height: 395px;
  }
            `;
const VideoIcon = styled.div`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60px;
            height: 60px;
            background-color: rgba(0, 0, 0, 0.3);
            border: 3px solid #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            &::after {
              content: '';
            width: 0;
            height: 0;
            border-left: 14px solid #fff;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
  }

            @media (min-width: 395px) {
              width: 70px;
            height: 70px;

            &::after {
              border - left: 15px solid #444;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
    }
  }
            `;
const Lock = styled.div`
  position: relative;
  width: 40px;
  height: 50px;
  margin: 50px auto;
`;

const LockShakle = styled.div`
  position: absolute;
  top: -30px;
  left: 10px;
  width: 40px;
  height: 30px;
  border: 5px solid #444;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: none;
  background: transparent;
`;

const LockBody = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 50px;
  background: #444;
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
`;
const Audio = styled(AudioPlayer)`
  direction: ltr;
`;

const AudioWrapper = styled.div`
  width: 100%;
`;
