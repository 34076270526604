import React, { useState } from "react";
import styled from "styled-components";
import {
  useSearchParams,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import CloseIcon from "@material-ui/icons/Close";

import DateRangeIcon from "@mui/icons-material/DateRange";

import { FacetsStore, ItemsStore } from "./Store";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment";

import Autocomplete from "@mui/material/Autocomplete";

import "moment/locale/he";

const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
  typography: {
    fontFamily: ["Rubik"],
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: { border: 0 },
        root: {
          flex: "1",
          fontFamily: "Rubik",
          fontSize: "16px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1",
          letterSpacing: "normal",
          textAlign: "center",
          color: "#4a4a4a",
          width: "100%",
          paddingRight: "12px !important",
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          height: "calc(100% - 2px)",
          width: "100%",
        },

        endAdornment: {
          position: "unset",
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Rubik",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: "Rubik",
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            display: "flex",
            justifyContent: "start",
            width: "100%",
          },
        },
      },
    },

    PrivatePickersFadeTransitionGroup: {
      styleOverrides: {
        root: {
          fontFamily: "Rubik",
        },
      },
    },
  },
});

export const AdvancedSearch = ({ searchTerm }) => {
  const { setItems } = ItemsStore();
  let location = useLocation();
  let navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const { collections, creators } = FacetsStore();
  const [params, setSearchParams] = useSearchParams();

  const [startDate, setStartDate] = useState(
    params && params.get("startDateFilter")
      ? moment.unix(params.get("startDateFilter"))
      : null
  );
  const [endDate, setEndDate] = useState(
    params && params.get("endDateFilter")
      ? moment.unix(params.get("endDateFilter"))
      : null
  );

  const handleStartDate = (value) => {
    setStartDate(value);
    if (value && value.isValid()) {
      params.set("startDateFilter", value.unix());
    } else {
      params.delete("startDateFilter");
    }
  };

  const handleEndDate = (value) => {
    setEndDate(value);
    if (value && value.isValid()) {
      params.set("endDateFilter", value.unix());
    } else {
      params.delete("endDateFilter");
    }
  };

  const [performSearch, setPerformSearch] = useState(false);

  const options = [
    { value: "", label: "שדות הטקסט הנפוצים" },
    { value: "dcTitle", label: "הכותרת" },
    { value: "dcDescription", label: "התיאור" },
    { value: "storageLocation", label: "המיקום" },
    { value: "id", label: "מזהה רשומה" },
  ];

  const termOptions = [
    { value: "any", label: "המילים השלמות" },
    { value: "exact", label: "הביטוי המדויק" },
    { value: "start", label: "מילה בודדת שמתחילה באותיות" },
    { value: "end", label: "מילה בודדת שנגמרת באותיות" },
    { value: "fuzzy", label: "מילה שמזכירה" },
  ];

  const accessOptions = [
    { value: "", label: "הכל" },
    { value: "public", label: "ציבורי" },
    { value: "private", label: "פרטי" },
    { value: "secret", label: "סודי" },
  ];

  let defaultCollectionOption = params.get("dcCollectionFilter")
    ? params.get("dcCollectionFilter")
    : "כולם";

  let defaultCreatorOption = params.get("dcCreatorFilter")
    ? params.get("dcCreatorFilter")
    : "כולם";

  const filterCollectionCB = (option) => {
    if (option === "כולם") {
      params.delete("dcCollectionFilter", "");
    } else {
      params.set("dcCollectionFilter", option.target.innerText);
    }
  };

  const filterCreatorCB = (option) => {
    if (option === "כולם") {
      params.set("dcCreatorFilter", "");
    } else {
      params.set("dcCreatorFilter", option.target.innerText);
    }
  };

  const filterAccessRightsCB = (option) => {
    params.set("dcAccessRights", option.value);
  };

  let defaultTermOption = termOptions.filter(
    (object) => object.value === params.get("searchTermModifier")
  );

  if (defaultTermOption.length > 0) {
    defaultTermOption = defaultTermOption[0].label;
  } else {
    defaultTermOption = "any";
  }

  let defaultOption = options.filter(
    (object) => object.value === params.get("searchInFields")
  );
  if (defaultOption.length > 0) {
    defaultOption = defaultOption[0].label;
  } else {
    defaultOption = "";
  }

  let defaultAccessRightsOption = accessOptions.filter(
    (object) => object.value === params.get("dcAccessRights")
  );
  if (defaultAccessRightsOption.length > 0) {
    defaultAccessRightsOption = defaultAccessRightsOption[0].label;
  } else {
    defaultAccessRightsOption = "";
  }

  const filterInFieldsCB = (option) => {
    params.set("searchInFields", option.value);
  };

  const termFilterCB = (option) => {
    params.set("searchTermModifier", option.value);
  };

  if (performSearch) {
    setItems([]);
    document.documentElement.scrollTop = 0;

    const current = Object.fromEntries([...params]);

    if (current["searchTerm"] === undefined) {
      params.set("searchTerm", "*");
    }

    if (current["searchTermModifier"] === undefined) {
      params.set("searchTermModifier", "any");
    }

    params.set("firstRow", 1);

    if (current["numberOfRows"] === undefined) {
      params.set("numberOfRows", 50);
    }

    if (current["sortField"] === undefined) {
      params.set("sortField", "dcDate");
    }

    if (current["sortOrder"] === undefined) {
      params.set("sortOrder", "desc");
    }

    setSearchParams(createSearchParams(params));

    //TODO: handle in case i look up the same thing

    setPerformSearch(false);
    setVisible(false);

    if (location.pathname === "/welcome") {
      navigate("/search?" + params.toString(), { replace: false });
    } else if (location.pathname === "/kedma-welcome") {
      navigate("/kedma?" + params.toString(), { replace: false });
    }
  }

  return (
    <>
      {visible && (
        <ThemeProvider theme={theme}>
          <Container>
            <TitleContainer>
              <Title>חיפוש מתקדם</Title>
              <StyledCloseIcon
                onClick={() => {
                  setVisible(false);
                  document.documentElement.scrollTop = 0;
                }}
              />
            </TitleContainer>
            <Inner>
              <Row>
                <SearchLabel>
                  <SearchLabelText>אפשרויות החיפוש</SearchLabelText>
                </SearchLabel>
                <SearchInput>
                  <Selector
                    options={termOptions}
                    value={defaultTermOption}
                    onChange={termFilterCB}
                  />

                  <DecoratorLine />
                </SearchInput>
              </Row>
              <Row>
                <SearchLabel>
                  <SearchLabelText>חיפוש לפי תאריך</SearchLabelText>
                </SearchLabel>
                <SearchInputCalender>
                  <LocalizationProvider dateAdapter={AdapterMoment} locale="he">
                    <SearchInputInnerContainerCalendar>
                      <DatePickerStyled
                        label="מתאריך"
                        value={startDate}
                        emptyLabel=""
                        cancelText="ביטול"
                        okText="אישור"
                        onChange={handleStartDate}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          OpenPickerIcon: DateRangeIcon,
                        }}
                      />
                      <DecoratorLineCalendar />
                    </SearchInputInnerContainerCalendar>
                    <CalendarsGap></CalendarsGap>
                    <SearchInputInnerContainerCalendar>
                      <DatePickerStyled
                        label="עד תאריך"
                        value={endDate}
                        cancelText="ביטול"
                        okText="אישור"
                        onChange={handleEndDate}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          OpenPickerIcon: DateRangeIcon,
                        }}
                      />
                      <DecoratorLineCalendar />
                    </SearchInputInnerContainerCalendar>
                  </LocalizationProvider>
                </SearchInputCalender>
              </Row>
              <Row>
                <SearchLabel>
                  <SearchLabelText>שדה לחיפוש</SearchLabelText>
                </SearchLabel>
                <SearchInput>
                  <Selector
                    options={options}
                    value={defaultOption}
                    onChange={filterInFieldsCB}
                  />

                  <DecoratorLine />
                </SearchInput>
              </Row>
              <Row>
                <SearchLabel>
                  <SearchLabelText>מחבר</SearchLabelText>
                </SearchLabel>
                <SearchInput>
                  <Autocomplete
                    id="combo-box-demo"
                    options={creators}
                    defaultValue={defaultCreatorOption}
                    onChange={filterCreatorCB}
                    noOptionsText="לא נמצאו אופציות"
                    renderInput={(params) => <TextField {...params} />}
                  />

                  <DecoratorLine />
                </SearchInput>
              </Row>
              <Row>
                <SearchLabel>
                  <SearchLabelText>חיפוש באוסף</SearchLabelText>
                </SearchLabel>
                <SearchInput>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={collections}
                    defaultValue={defaultCollectionOption}
                    onChange={filterCollectionCB}
                    noOptionsText="לא נמצאו אופציות"
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DecoratorLine />
                </SearchInput>
              </Row>
              <Row>
                <SearchLabel>
                  <SearchLabelText>סיווג</SearchLabelText>
                </SearchLabel>
                <SearchInput>
                  <Selector
                    options={accessOptions}
                    value={defaultAccessRightsOption}
                    onChange={filterAccessRightsCB}
                  />

                  <DecoratorLine />
                </SearchInput>
              </Row>
              <Row>
                <SearchButton onClick={() => setPerformSearch(true)}>
                  <SearchButtonLabel>חיפוש</SearchButtonLabel>
                </SearchButton>
              </Row>
            </Inner>
          </Container>
        </ThemeProvider>
      )}
    </>
  );
};

const DatePickerStyled = styled(DatePicker)``;
const Selector = styled(Dropdown)`
  &.Dropdown-root {
    width: 100%;
  }

  & .Dropdown-control {
    margin-bottom: 12px;
    background-color: #edf0f5;
    border: 0;
    padding: 0px;
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 500px) {
      margin-bottom: 0px;
      background-color: #edf0f5;

      width: 317px;
      margin-top: 0;
      height: 53px;
      display: flex;
      align-items: center;
    }
  }

  & .Dropdown-control:hover {
    box-shadow: none;
  }

  & .Dropdown-arrow-wrapper {
    cursor: pointer;
    width: 24px;
    margin-left: 6px;
  }

  & .Dropdown-arrow {
    position: unset;
    border-color: black transparent transparent;
  }

  & .is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }

  & .Dropdown-placeholder {
    height: 24px;
    font-family: Rubik;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-right: 13px;

    @media (min-width: 500px) {
      margin-left: auto;
      font-family: Rubik;
    }
  }

  & .Dropdown-menu {
    width: 100%;
    overflow: auto;
    font-family: Rubik;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    @media (min-width: 500px) {
      margin-left: auto;
    }
  }

  & .Dropdown-option {
    height: auto;
    font-family: Rubik;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: rgba(0, 0, 0, 0.87);
    @media (min-width: 500px) {
      margin-left: auto;
    }
  }
`;

const Container = styled.div`
  overflow: auto;
  width: 100vw;
  z-index: 101;
  height: 100vh;
  top: 0;
  position: fixed;
  background-color: white;

  @media (min-width: 500px) {
    height: 530px;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
  }
`;

const Inner = styled.div`
  background-color: white;
  @media (min-width: 500px) {
    margin-left: 16px;
    margin-right: 16px;
    height: calc(100% - 16px);
    display: grid;
    grid-template-rows: repeat(5, 72px) 1fr;
    border-radius: px;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
      0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: 16px;
  margin-right: 22px;
  margin-left: 22px;
  flex-direction: column;
  align-items: center;
  @media (min-width: 500px) {
    flex-direction: row;
    margin-left: 19px;
    margin-right: 36px;
    margin-top: 0;
  }
`;

const SearchInput = styled.div`
  width: 100%;
  height: 50px;
  background-color: #edf0f5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  border-radius: 8px;

  @media (min-width: 500px) {
    width: 317px;
    margin-top: 17px;
    height: 55px;
    border-radius: 4px;
    background-color: #edf0f5;
  }
`;

const SearchInputCalender = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (min-width: 500px) {
    width: 317px;
    margin-top: 17px;
    height: 55px;
    flex-direction: row;
    justify-content: space-between;
    background-color: #edf0f5;
  }
`;

const CalendarsGap = styled.div`
  width: 20px;
  margin-top: 0;
  height: 56px;
  background-color: white;
`;

const SearchInputInnerContainerCalendar = styled.div`
  border-radius: 8px;
  background-color: #edf0f5;

  @media (min-width: 500px) {
    width: 100%;
    margin-top: 0;
    height: 55px;
    display: flex;
    align-items: end;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const SearchLabel = styled.div`
  width: 100%;
  height: 30px;
  margin-bottom: 8px;
  @media (min-width: 500px) {
    flex: 1;
    background-color: white;
    margin-top: 17px;
    height: 55px;
  }
`;

const SearchLabelText = styled.div`
  flex: 1;
  background-color: white;
  margin-top: 5px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);

  @media (min-width: 500px) {
  }
`;

const DecoratorLine = styled.div`
  @media (min-width: 500px) {
    height: 2px;
    background-color: #4a4a4a;
    margin: 0px 0 0;
    width: 100%;
  }
`;

const DecoratorLineCalendar = styled.div`
  @media (min-width: 500px) {
    width: 100%;
    height: 2px;
    background-color: #4a4a4a;
    margin: 0px 0 0;
  }
`;

const SearchButton = styled.button`
  align-self: end;
  width: 70px;
  height: 36px;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 24px 0px;
  margin-bottom: 150px;
  background-color: #6200ee;
  border: 0;
  cursor: pointer;

  @media (min-width: 500px) {
    margin-bottom: 14px;
    width: 70px;
    height: 36px;
    border-radius: 4px;
    background-color: #6200ee;
    border: 0;
    margin-right: auto;
  }
`;

const SearchButtonLabel = styled.div`
  width: 32px;
  height: 16px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
`;

const StyledCloseIcon = styled(CloseIcon)`
cursor: pointer;
width: 24px;
height: 24px;
margin-left: 16px;

}`;

const Title = styled.div`
height: 24px;
font-family: Rubik;
font-size: 20px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: normal;
text-align: right;
color: #000;
margin-right: 24px;
width: 100%;

}`;

const TitleContainer = styled.div`
  background-color: #e7f6ff;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 500px) {
    display: none;
  }
`;
