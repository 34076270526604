import React, { useState } from "react";
import styled from "styled-components";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { ItemsStore } from "./Store";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

export const SorterItem = ({ title, options, cb }) => {
  const defaultOption = options[0];

  return (
    <SorterItemContainer>
      <Title>{title}</Title>
      <Selector options={options} onChange={cb} value={defaultOption} />
    </SorterItemContainer>
  );
};

export const View = ({ title, options, cb, params }) => {
  const defaultOption =
    params.get("view") === "table" ? options[1] : options[0];

  return (
    <SorterItemContainer>
      <Title>{title}</Title>
      <Selector options={options} onChange={cb} value={defaultOption} />
    </SorterItemContainer>
  );
};

export const Sorter = () => {
  const { setItems } = ItemsStore();
  const filterOptions = ["תאריך", "כותרת", "קליטה"];
  const orderOptions = ["יורד", "עולה"];
  const viewOptions = ["רגילה", "טבלה"];
  const [reorder, setReorder] = useState(false);
  const [refilter, setRefilter] = useState(false);
  const [params, setSearchParams] = useSearchParams();

  if (reorder || refilter) {
    setItems([]);
    setSearchParams(createSearchParams(params));
    setReorder(false);
    setRefilter(false);
  }

  const filterCallback = (value) => {
    let filter = "";

    switch (value.value) {
      case "כותרת":
        filter = "dcTitle";
        break;
      case "תאריך":
        filter = "dcDate";
        break;
      case "קליטה":
        filter = "importTime";
        break;
      default:
        console.log("unknown sort field " + value.value);
    }

    if (filter === params.get("sortField")) return;

    params.set("sortField", filter);
    params.set("firstRow", 1);

    setSearchParams(createSearchParams(params));

    setRefilter(true);
  };

  const orderCallback = (value) => {
    const order = value.value === "עולה" ? "asc" : "desc";
    if (order === params.get("sortOrder")) return;
    console.log(order);

    params.set("sortOrder", order);
    params.set("firstRow", 1);

    setSearchParams(createSearchParams(params));

    setReorder(true);
  };

  const viewCallback = (option) => {
    switch (option.value) {
      case "טבלה":
        params.set("view", "table");
        break;
      default:
        params.delete("view");
    }
    setItems([]);

    setSearchParams(createSearchParams(params));
  };

  return (
    <Container>
      <SorterItem
        title={"מיון לפי:"}
        options={filterOptions}
        cb={filterCallback}
      />
      <SorterItem title={"בסדר:"} options={orderOptions} cb={orderCallback} />
      <View
        title={"תצוגה:"}
        options={viewOptions}
        cb={viewCallback}
        params={params}
      />
    </Container>
  );
};

const Selector = styled(Dropdown)`
  & .Dropdown-control {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    height: 100%;
    padding: 0;
    background-color: transparent;
  }

  & .Dropdown-control:hover {
    box-shadow: none;
  }

  & .Dropdown-arrow-wrapper {
    position: unset;
    cursor: pointer;

    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .Dropdown-arrow {
    position: unset;
    border-color: black transparent transparent;
  }

  & .is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }

  & .Dropdown-placeholder {
    height: 21px;
    margin-left: 8px;
    font-family: Rubik;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #303235;
  }

  & .Dropdown-menu {
    font-family: Rubik;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #303235;
  }

  & .Dropdown-option {
    font-family: Rubik;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #303235;
  }
`;
const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5x;
`;

const SorterItemContainer = styled.div`
  height: 24px;
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e7f6ff;
  margin-bottom: 24px;
`;

const Title = styled.div`
  height: 21px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #303235;
  direction: rtl;
  margin-left: 8px;
`;
