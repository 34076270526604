import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ItemsStore } from "./Store";
import archieExports from "../archie-exports";
import { API } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { Pagination } from "../components/Pagination";
import { Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useSearchParams } from "react-router-dom";
const Button = styled.button`
  background-color: #4a90e2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 50px; /* Rounded edges for a modern look */
  cursor: pointer;
  margin: 10px 5px;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #357ab8;
    transform: scale(1.05); /* Slight scaling on hover for a modern effect */
  }

  &:active {
    background-color: #2a5b8e;
    transform: scale(0.98); /* Slight shrink effect when pressed */
  }
`;
const Container = styled.div`
  direction: rtl;
  padding: 16px;
  font-family: "Rubik", sans-serif;
  background-color: #f9f9f9;
  color: #333;
`;

const Submit = styled(Button)`
  background-color: ${({ success }) => (success ? "lightgreen" : "#d0021b")};
  color: white;
  &:hover {
    background-color: ${({ success }) =>
      success ? "green" : "darkred"}; /* Adjust hover colors */
  }
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const TableHeader = styled.th`
  background-color: #4a90e2;
  color: white;
  padding: 12px;
  text-align: right;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #eaeaea;
`;

const TableCell = styled.td`
  padding: 12px;
  text-align: ${({ contentAlign }) => (contentAlign ? "center" : "right")};
  border: 1px solid #eaeaea;
  font-size: 16px;
`;

const Input = styled.textarea`
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 5px 0;
  border-radius: 4px;
  font-size: 16px;
  word-wrap: break-word;
  word-break: break-all;
  scrollbar-width: none;
`;

const DateInput = styled.input`
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 5px 0;
  border-radius: 4px;
  font-size: 16px;
  word-wrap: break-word;
  word-break: break-all;
  resize: none;
`;

const TextArea = styled.textarea`
  padding: 8px;
  width: 100%;
  min-width: 150px;
  height: 60px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 5px 0;
  border-radius: 4px;
  font-size: 16px;
  scrollbar-width: none;
`;

const Select = styled.select`
  min-width: 80px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 5px 0;
  border-radius: 4px;
  font-size: 16px;
`;

const BulkEditContainer = styled.div`
  margin-bottom: 20px;
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TagLink = styled.a`
  color: blue;
  cursor: pointer;
`;

export const EditorTable = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { items, setItems } = ItemsStore();
  const [rows, setRows] = useState([]);
  const [isEditingAll, setIsEditingAll] = useState(true);
  const [changedRows, setChangedRows] = useState({});
  const [readState, setReadState] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkEditField, setBulkEditField] = useState("");
  const [bulkEditValue, setBulkEditValue] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params, setSearchParams] = useSearchParams();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // DD-MM-YYYY format
  };

  // Populate rows from items on mount
  useEffect(() => {
    setLoading(true);
    if (items && items.length > 0) {
      const newRows = items.map((item) => ({
        id: item.id,
        dcTitle: item.dcTitle,
        dcDescription: item.dcDescription,
        dcDate: item.dcDate,
        dcCreator: item.dcCreator,
        dcSubject: item.dcSubject,
        storageLocation: item.storageLocation,
        dcType: item.dcType,
        dcAccessRights: item.dcAccessRights,
        dcIsPartOf: item.dcIsPartOf,
        dcFormat: item.dcFormat,
      }));
      setRows(newRows);
      setLoading(false);
    }
  }, [items]);

  const handleInputChange = (id, name, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [name]: value } : row))
    );
    setChangedRows((prev) => ({
      ...prev,
      [id]: { ...prev[id], id, [name]: value },
    }));
  };

  const handleEditAllClick = () => {
    setIsEditingAll((prev) => !prev);
  };

  const handleRowSelection = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const applyBulkEdit = () => {
    if (!bulkEditField) {
      alert("אנא בחר שדה לעריכה");
      return;
    }
    setRows((prevRows) =>
      prevRows.map((row) =>
        selectedRows.includes(row.id)
          ? { ...row, [bulkEditField]: bulkEditValue }
          : row
      )
    );

    setChangedRows((prev) => {
      const updatedChanges = { ...prev };
      selectedRows.forEach((id) => {
        updatedChanges[id] = {
          ...updatedChanges[id],
          id,
          [bulkEditField]: bulkEditValue,
        };
      });
      return updatedChanges;
    });
  };

  const handleSort = (column) => {
    const newDirection =
      sortedColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortedColumn(column);
    setRows((prevRows) => {
      return [...prevRows].sort((a, b) => {
        const aValue = a[column] || "";
        const bValue = b[column] || "";

        if (aValue === "" || bValue === "") {
          return aValue === "" ? 1 : -1;
        }

        if (aValue < bValue) return newDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return newDirection === "asc" ? 1 : -1;
        return 0;
      });
    });
  };

  const getSortIcon = (col) => {
    if (col !== sortedColumn) return null;
    return sortDirection === "asc" ? "▲" : "▼";
  };

  const handleSubmit = async () => {
    setSuccess(false);
    setSubmitLoading(true);

    const updates = Object.values(changedRows);
    try {
      const updatePromises = updates.map((row) => {
        if (row["dcDate"]) {
          row["dcDate"] = new Date(row["dcDate"]).toISOString();
        }

        return API.patch("archie_api", "/docs", { body: [row] }).then((val) => {
          if (val.statusCode !== 200) {
            throw new Error(`Failed to update row: ${JSON.stringify(row)}`);
          }
        });
      });

      await Promise.all(updatePromises);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setChangedRows({});
      }, 3000);
    } catch (error) {
      alert("העדכון נכשל");
      console.error("Error during update: ", error);
      setChangedRows({});
    } finally {
      setSubmitLoading(false);
    }
  };

  const tagHandler = (e, tagType) => {
    if (e.target.text) {
      setItems([]);
      if (tagType === "dcSubject") params.set(tagType, e.target.text);
      else if (tagType === "dcCreator")
        params.set("dcCreatorFilter", e.target.text);
      params.set("searchTerm", "*");

      params.set("searchTermModifier", "any");

      params.set("firstRow", 1);

      params.set("numberOfRows", 50);

      params.set("sortField", "dcDate");

      params.set("dcTypeFilter", "");

      params.set("sortOrder", "desc");
      setSearchParams(params);
    }
  };

  const tag = (item, arrayLen, tagType) => {
    return (
      <>
        <TagLink onClick={(e) => tagHandler(e, tagType)}>{item}</TagLink>
        {arrayLen > 1 && item.length > 0 ? <span> | </span> : null}
      </>
    );
  };

  const saveChanges = () => {
    setReadState((prev) => !prev);
  };

  const getThumbnailUrl = (dcAccess, thumbnailId) => {
    if (dcAccess === "private") {
      return `${archieExports["private_assets_bucket"]}/thumbnails/${thumbnailId}.webp`;
    }
    return `${archieExports["public_assets_bucket"]}/thumbnails/${thumbnailId}.webp`;
  };

  const fileTypes = {
    video: "סרטון",
    image: "תמונה",
    audio: "קובץ קול",
    text: "מסמך",
  };
  const accessTypes = { public: "ציבורי", private: "פרטי", secret: "סודי" };

  const [hoveredImage, setHoveredImage] = useState(null);

  const assetLink = (id, dcAccess, dcFormat) => {
    if (dcAccess === "private") {
      return `${archieExports["private_assets_bucket"]}/originals/${id}.${dcFormat}`;
    }
    return `${archieExports["public_assets_bucket"]}/originals/${id}.${dcFormat}`;
  };

  return (
    <Container>
      {user ? (
        <>
          <Button onClick={handleEditAllClick}>
            {isEditingAll ? "עריכת מקבץ" : "עריכה לכל"}
          </Button>

          {!isEditingAll ? (
            <BulkEditContainer>
              <Select
                value={bulkEditField}
                onChange={(e) => setBulkEditField(e.target.value)}
              >
                <option value="">בחירת שדה</option>
                <option value="dcTitle">כותרת</option>
                <option value="dcDescription">תיאור</option>
                <option value="dcType">סוג</option>
                <option value="dcAccessRights">סיווג</option>
                <option value="dcDate">תאריך</option>
                <option value="dcSubject">נושאים</option>
                <option value="dcIsPartOf">אוסף</option>
              </Select>

              {bulkEditField === "dcDate" ? (
                <DateInput
                  type="date"
                  value={bulkEditValue}
                  onChange={(e) => setBulkEditValue(e.target.value)}
                />
              ) : bulkEditField === "dcSubject" ? (
                <Input
                  type="text"
                  value={bulkEditValue ? bulkEditValue.join(",") : ""}
                  onChange={(e) => setBulkEditValue(e.target.value.split(","))}
                />
              ) : bulkEditField === "dcDescription" ? (
                <TextArea
                  value={bulkEditValue}
                  onChange={(e) => setBulkEditValue(e.target.value)}
                  placeholder="הכנס תיאור"
                />
              ) : bulkEditField === "dcType" ||
                bulkEditField === "dcAccessRights" ? (
                <Select
                  value={bulkEditValue}
                  onChange={(e) => setBulkEditValue(e.target.value)}
                >
                  {bulkEditField === "dcType" ? (
                    <>
                      <option value="">בחירת סוג</option>
                      <option value="image">תמונה</option>
                      <option value="text">מסמך</option>
                      <option value="video">סרטון</option>
                      <option value="audio">קובץ קול</option>
                    </>
                  ) : (
                    <>
                      <option value="">בחירת סיווג</option>
                      <option value="public">ציבורי</option>
                      <option value="private">פרטי</option>
                      <option value="secret">סודי</option>
                    </>
                  )}
                </Select>
              ) : (
                <Input
                  type="text"
                  value={bulkEditValue}
                  onChange={(e) => setBulkEditValue(e.target.value)}
                  placeholder="הכנס ערך"
                />
              )}
              <Button onClick={applyBulkEdit}>שמירה</Button>
            </BulkEditContainer>
          ) : (
            <Button onClick={saveChanges}>
              {readState ? "עריכה" : "צפייה"}
            </Button>
          )}
          {Object.keys(changedRows).length > 0 ? (
            <Submit
              success={success}
              onClick={handleSubmit}
              disabled={submitLoading}
            >
              {submitLoading ? (
                <label>
                  <CircularProgress
                    size={20}
                    style={{ color: "white", position: "relative" }}
                  />
                </label>
              ) : success ? (
                "נשלח"
              ) : (
                "שליחה"
              )}
            </Submit>
          ) : null}
        </>
      ) : null}
      <Pagination />
      {loading || items.length === 0 ? (
        <Stack alignItems="center">
          <CircularProgress
            sx={{ color: "black" }}
            variant="indeterminate"
            size={50}
          ></CircularProgress>
        </Stack>
      ) : (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                {!isEditingAll && <TableHeader>בחירה</TableHeader>}
                <TableHeader>מזהה</TableHeader>
                <TableHeader>תצוגה מקדימה</TableHeader>
                <TableHeader onClick={() => handleSort("dcTitle")}>
                  כותרת<span>{getSortIcon("dcTitle")}</span>
                </TableHeader>
                <TableHeader onClick={() => handleSort("dcDescription")}>
                  תיאור<span>{getSortIcon("dcDescription")}</span>
                </TableHeader>
                <TableHeader onClick={() => handleSort("dcDate")}>
                  תאריך<span>{getSortIcon("dcDate")}</span>
                </TableHeader>
                <TableHeader onClick={() => handleSort("dcSubject")}>
                  נושאים<span>{getSortIcon("dcSubject")}</span>
                </TableHeader>
                <TableHeader onClick={() => handleSort("dcCreator")}>
                  יוצרים<span>{getSortIcon("dcCreator")}</span>
                </TableHeader>

                <TableHeader onClick={() => handleSort("storageLocation")}>
                  מיקום<span>{getSortIcon("storageLocation")}</span>
                </TableHeader>
                <TableHeader onClick={() => handleSort("dcType")}>
                  סוג<span>{getSortIcon("dcType")}</span>
                </TableHeader>
                <TableHeader onClick={() => handleSort("dcAccessRights")}>
                  סיווג<span>{getSortIcon("dcAccessRights")}</span>
                </TableHeader>
                <TableHeader onClick={() => handleSort("dcIsPartOf")}>
                  אוסף<span>{getSortIcon("dcIsPartOf")}</span>
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.id}>
                  {!isEditingAll && (
                    <TableCell contentAlign>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(row.id)}
                        onChange={() => handleRowSelection(row.id)}
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <a
                      href={assetLink(row.id, row.dcAccessRights, row.dcFormat)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.id}
                    </a>
                  </TableCell>
                  <TableCell
                    contentAlign
                    onMouseEnter={() => setHoveredImage(row.id)}
                    onMouseLeave={() => setHoveredImage(null)}
                    style={{ position: "relative" }} // Add relative positioning to the TableCell
                  >
                    {row.dcType !== "audio" ? (
                      <>
                        <img
                          src={getThumbnailUrl(row.dcAccessRights, row.id)}
                          width="50"
                          height="auto"
                          alt="תמונת ממוזערת"
                        />
                        {hoveredImage === row.id && (
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "50%",
                              transform: "translateX(-50%)",
                              width: "300px",
                              backgroundColor: "white",
                              padding: "10px",
                              borderRadius: "5px",
                              zIndex: "10",
                            }}
                          >
                            <img
                              src={getThumbnailUrl(row.dcAccessRights, row.id)}
                              alt="תמונה ממוזערת"
                              width="300"
                              style={{
                                borderRadius: "8px",
                              }}
                            />
                          </div>
                        )}
                      </>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {isEditingAll && !readState ? (
                      <TextArea
                        type="text"
                        value={row.dcTitle}
                        onChange={(e) =>
                          handleInputChange(row.id, "dcTitle", e.target.value)
                        }
                      />
                    ) : (
                      row.dcTitle
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditingAll && !readState ? (
                      <TextArea
                        value={row.dcDescription}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "dcDescription",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      row.dcDescription
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditingAll && !readState ? (
                      <DateInput
                        type="date"
                        value={row.dcDate && row.dcDate.substring(0, 10)}
                        onChange={(e) =>
                          handleInputChange(row.id, "dcDate", e.target.value)
                        }
                      />
                    ) : (
                      row.dcDate && formatDate(row.dcDate)
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditingAll && !readState ? (
                      <Input
                        type="text"
                        value={row.dcSubject ? row.dcSubject.join(",") : ""}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "dcSubject",
                            e.target.value.split(",")
                          )
                        }
                      />
                    ) : (
                      row.dcSubject &&
                      row.dcSubject.map((i) =>
                        tag(i, row.dcSubject.length, "dcSubject")
                      )
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditingAll && !readState ? (
                      <Input
                        type="text"
                        value={row.dcCreator ? row.dcCreator.join(",") : ""}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "dcCreator",
                            e.target.value.split(",")
                          )
                        }
                      />
                    ) : (
                      row.dcCreator &&
                      row.dcCreator.map((i) =>
                        tag(i, row.dcCreator.length, "dcCreator")
                      )
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditingAll && !readState ? (
                      <Input
                        type="text"
                        value={row.storageLocation}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "storageLocation",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      row.storageLocation
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditingAll && !readState ? (
                      <Select
                        value={row.dcType}
                        onChange={(e) =>
                          handleInputChange(row.id, "dcType", e.target.value)
                        }
                      >
                        <option value="image">תמונה</option>
                        <option value="text">מסמך</option>
                        <option value="video">סרטון</option>
                        <option value="audio">קובץ קול</option>
                      </Select>
                    ) : (
                      fileTypes[row.dcType]
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditingAll && !readState ? (
                      <Select
                        value={row.dcAccessRights}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "dcAccessRights",
                            e.target.value
                          )
                        }
                      >
                        <option value="public">ציבורי</option>
                        <option value="private">פרטי</option>
                        <option value="secret">סודי</option>
                      </Select>
                    ) : (
                      accessTypes[row.dcAccessRights]
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditingAll && !readState ? (
                      <Input
                        type="text"
                        value={row.dcIsPartOf}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "dcIsPartOf",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      row.dcIsPartOf
                    )}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};
