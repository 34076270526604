import React, { useEffect, useState } from "react";
import {
  createSearchParams,
  useSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";

import styled from "styled-components";
import Chip from "@material-ui/core/Chip";
import { FacetsStore, ItemsStore } from "./Store";

function Chips() {
  const { setItems } = ItemsStore();

  const [type, setType] = useState(null);
  let location = useLocation();
  let navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const { facets } = FacetsStore();

  const getCountByType = (type) => {
    if (facets.counters && facets.counters.dcType) {
      const i = facets.counters.dcType.indexOf(type);
      return i >= 0 ? facets.counters.dcType[i + 1] : 0;
    } else {
      return 0;
    }
  };

  // Effect to handle type changes
  useEffect(() => {
    if (type === null) return;

    // Clear items
    setItems([]);

    // Clone the current params and modify
    const current = Object.fromEntries([...params]);

    if (!current["searchTerm"]) {
      params.set("searchTerm", "*");
    }
    if (!current["searchTermModifier"]) {
      params.set("searchTermModifier", "any");
    }
    params.set("firstRow", 1);
    params.set("numberOfRows", 50);
    if (!current["sortField"]) {
      params.set("sortField", "dcDate");
    }
    if (!current["sortOrder"]) {
      params.set("sortOrder", "desc");
    }
    params.set("dcTypeFilter", type);

    if (location.pathname === "/kedma-welcome") {
      params.set("dcCollectionFilter", "קיבוץ קדמה");
    }

    setSearchParams(createSearchParams(params));

    // Navigate to the appropriate page
    if (location.pathname === "/welcome") {
      navigate(`/search?${params.toString()}`, { replace: false });
    } else if (location.pathname === "/kedma-welcome") {
      navigate(`/kedma?${params.toString()}`, { replace: false });
    }

    // Reset type after handling
    setType(null);
  }, [type, params, setSearchParams, location.pathname, navigate, setItems]);
  return (
    <Container>
      <ChipsContainer>
        <StyledChip
          label={
            "כל הסוגים - " +
            (facets && facets.total
              ? facets.total - getCountByType("collection")
              : 0)
          }
          component="a"
          clickable
          onClick={() => {
            if (params.get("dcTypeFilter") !== "") setType("");
          }}
          selected={params.get("dcTypeFilter") === ""}
        />
        <StyledChip
          label={"תמונות - " + getCountByType("image")}
          component="a"
          clickable
          onClick={() => {
            if (params.get("dcTypeFilter") !== "image") setType("image");
          }}
          selected={params.get("dcTypeFilter") === "image"}
        />
        <StyledChip
          label={"מסמכים - " + getCountByType("text")}
          component="a"
          clickable
          onClick={() => {
            if (params.get("dcTypeFilter") !== "text") setType("text");
          }}
          selected={params.get("dcTypeFilter") === "text"}
        />
        <StyledChip
          label={"סרטונים - " + getCountByType("video")}
          component="a"
          clickable
          onClick={() => {
            if (params.get("dcTypeFilter") !== "video") setType("video");
          }}
          selected={params.get("dcTypeFilter") === "video"}
        />

        <StyledChip
          label={"קבצי קול - " + getCountByType("audio")}
          component="a"
          clickable
          onClick={() => {
            if (params.get("dcTypeFilter") !== "audio") setType("audio");
          }}
          selected={params.get("dcTypeFilter") === "audio"}
        />
      </ChipsContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  display: flex;

  @media (min-width: 800px) {
    width: auto;
  }
`;

const ChipsContainer = styled.div`
  margin: 8px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;
const StyledChip = styled(Chip)`
  margin-left: 8px;
  margin-top: 16px;
  &.MuiChip-root {
    background-color: white;
    border-radius: 16px;
    border: solid 1px #025ed0;
  }

  .MuiChip-label {
    height: 20px;
    font-family: Rubik;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    text-align: center;
    color: ${(props) => (props.selected ? "white" : "#025ed0")};
  }

  &.MuiChip-clickable {
    background-color: ${(props) => (props.selected ? "#033fa9" : "white")};
    .MuiChip-label {
      color: ${(props) => (props.selected ? "white" : "#025ed0")};
    }
  }

  &.MuiChip-clickable:hover {
    background-color: #033fa9;
    .MuiChip-label {
      color: white;
    }
  }

  &.MuiChip-clickable:focus {
    background-color: #033fa9;
    .MuiChip-label {
      color: white;
    }
  }
`;
export default Chips;
